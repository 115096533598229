<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center pb-0">
        <v-card width="850" elevation="3" id="dataapp">
          <v-card-title class="ml-10 mt-5"> </v-card-title>

          <v-card-text>
            <v-row class="ml-10" style="border-bottom: 1px groove">
              <v-col cols="9" class="mt-7">
                <v-row class="d-flex flex-column">
                  <v-col class="pt-0">
                    <v-text style="font-size: 2.1rem; color: #757575">
                      INVOICE
                    </v-text>
                  </v-col>
                  <v-col class="py-0"
                    ><p class="font-weight-medium py-0 mb-0">
                      BILL FROM :
                    </p></v-col
                  >
                  <v-col class="pb-0"
                    ><p class="mb-0 text-body-1">
                      {{ myinfo.name }}
                    </p>
                    <p class="mb-0 text-body-1">{{ myinfo.email }}</p>
                    <p class="mb-0 text-body-1">{{ myinfo.phone }}</p>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <p class="text-body-1 mb-0">{{ myinfo.address }}</p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="2" align-self="center">
                <div style="height: 70%; width: 70%">
                  <v-img :src="myinfo.logo_url" alt="Rooster crowing"></v-img>
                </div>
              </v-col>
            </v-row>
            <div>
              <v-row class="ml-10 pa-0 mt-5">
                <v-col class="py-0 mb-0" cols="8"
                  ><p class="mb-0 font-weight-medium">BILL TO :</p></v-col
                >
                <v-col cols="4" class="py-0 d-flex flex-row">
                  <p class="text-body-1 mb-0">INVOICE #</p>
                  <v-spacer></v-spacer>
                  <p class="text-body-1 mb-0 mr-13">{{ invoiceinfo.id }}</p>
                </v-col>

                <v-col cols="8" class="py-0">
                  <p class="text-body-1 mb-0 mt-2">
                    {{ billing_info.name }}
                  </p>
                </v-col>
                <v-col cols="4" class="py-0 d-flex flex-row">
                  <v-col class="py-0 d-flex flex-row pl-0 mr-5">
                    <p class="text-body-1 mb-0">Date:</p>
                    <v-spacer></v-spacer>
                    <p class="text-body-1 mb-0 mr-5">{{ invoieDate }}</p>
                  </v-col>
                </v-col>

                <v-col cols="8" class="py-0">
                  <p class="text-body-1 mb-0">
                    {{ billing_info.domain }}
                  </p>
                  <p class="text-body-1 mb-0">{{ billing_info.phone }}</p>

                  <v-col cols="4" class="pa-0">
                    <p class="text-body-1 mb-0">{{ billing_info.address }}</p>
                  </v-col>
                </v-col>
                <v-col
                  cols="4"
                  v-if="orderinfo.order_status == 'Unpaid'"
                  class="py-0"
                  dense
                >
                  <div
                    class="d-flex flex-row align-center mr-12"
                    style="background-color: #cfd3d4"
                  >
                    <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                      Amount Due:
                    </p>
                    <v-spacer></v-spacer>
                    <p class="mt-2 mb-1 font-weight-medium mr-5">
                      {{ orderinfo.due }}
                    </p>
                  </div>
                  <div>
                    <p
                      style="font-family: 'PT Serif', serif; bold; font-size:30px;"
                      class="mt-3 mb-2 font-weight-bold"
                    >
                      UNPAID
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  v-if="orderinfo.order_status == 'Paid'"
                  class="py-0"
                >
                  <p
                    style="font-family: 'PT Serif', serif; font-size: 30px"
                    class="mt-2 mb-0 font-weight-bold"
                  >
                    PAID
                  </p>
                </v-col>
              </v-row>
              <v-row> </v-row>
              <v-card min-height="500" elevation="0" class="mt-2">
                <v-data-table
                  :headers="headers"
                  :items="orders"
                  item-key="id"
                  class="elevation-0 mx-12"
                  hide-default-footer
                  dense
                >
                </v-data-table>

                <v-row>
                  <v-col cols="7"> </v-col>

                  <v-col cols="4" class="pr-0">
                    <div>
                      <div class="d-flex flex-row align-center pr-5">
                        <p class="mb-1 text-body-1 ml-2">Sub Total</p>
                        <v-spacer></v-spacer>
                        <p class="mb-1 text-body-1 mr-0 mb-0">
                          {{ invoiceinfo.sub_price }}
                        </p>
                      </div>
                      <div class="d-flex flex-row align-center pr-5">
                        <p class="text-body-1 ml-2 mb-0">Discount</p>
                        <v-spacer></v-spacer>
                        <p class="mr-0 text-body-1 mb-0">
                          {{ invoiceinfo.discount }}
                        </p>
                      </div>
                      <div class="d-flex flex-row align-center pr-5">
                        <p class="text-body-1 ml-2">VAT</p>
                        <v-spacer></v-spacer>
                        <p class="text-body-1 mr-0">0.00</p>
                      </div>
                      <div
                        class="d-flex flex-row align-center pr-5"
                        style="background-color: #cfd3d4"
                      >
                        <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                          Grand Total
                        </p>
                        <v-spacer></v-spacer>
                        <p class="mt-2 mb-1 font-weight-medium mr-0">
                          {{ invoiceinfo.price_total }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-card v-if="orderinfo.order_status == 'Paid'" elevation="0">
                  <div>
                    <div style="border: 1px groove" class="mx-12">
                      <v-row>
                        <v-row class="ml-5">
                          <v-col class="" cols="3"> Transaction Date </v-col>
                          <v-col cols="3"> GateWay</v-col>
                          <v-col cols="3"> Transaction ID </v-col>
                          <v-col cols="3"> Amount</v-col>
                        </v-row>
                      </v-row>
                    </div>
                    <div style="border: 1px groove" class="mx-12">
                      <v-row>
                        <v-row class="ml-5">
                          <v-col class="" cols="3">
                            <p
                              class="text-body-1 mb-0"
                              v-if="orderinfo.ordered_date"
                            >
                              {{ orderinfo.ordered_date }}
                            </p>
                          </v-col>
                          <v-col cols="3" class="text-body-1 mb-0"> N/A</v-col>
                          <v-col cols="3" class="text-body-1 mb-0"> N/A </v-col>
                          <v-col cols="3">
                            <p
                              v-if="invoiceinfo.price_total"
                              class="text-body-1 mb-0"
                            >
                              {{ invoiceinfo.price_total }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-row>
                    </div>
                  </div>
                </v-card>
              </v-card>

              <v-row class="ml-10 d-flex flex-row">
                <v-col cols="12" class="d-flex flex-row">
                  <p class="text-caption">
                    This Invoice Is Autogenareted By
                    {{ myinfo.name }} After Confirming Your Order And Payment
                  </p>
                  <v-spacer></v-spacer>

                  <p
                    class="mb-0"
                    v-if="invoiceinfo.admin_status == 'Processing'"
                  >
                    <v-btn color="indigo darken-4" @click="ApprovechildOrder"
                      >Approve</v-btn
                    >
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex justify-center py-0">
        <v-card elevation="0" class="mt-0">
          <v-btn
            text
            @click="printData"
            class="pa-0"
            style="padding-left: 5px !important"
          >
            <v-icon class="pr-1" large color="black">mdi-printer </v-icon>
            Print
          </v-btn>
          <v-btn
            text
            @click="printData"
            style="padding-left: 0px !important; padding-right: 10px !important"
          >
            <v-icon class="pr-0" large color="black">mdi-download </v-icon>
            download
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-container>
</template>
       


<script>
export default {
  data: () => ({
    invoiceinfo: {},
    orders: [],
    myinfo: {},
    invoieDate: "",
    billing_info: {},
    orderinfo: {},

    headers: [
      {
        text: "Name",
        sortable: false,
        value: "product_name",
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Color",
        value: "product_color",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Size",
        value: "product_size",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: " Unit",
        value: "product_unit",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader ",
      },

      {
        text: "Quantity ",
        value: "quantity",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Unit Price ",
        value: "unit_price",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      // {
      //   text: "Discount ",
      //   value: "total_discount",
      //   sortable: false,
      //   class: "blue-grey lighten-4 text-body-2 ",
      // },
      {
        text: "Amount",
        value: "total_price",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
    ],
  }),
  watch: {
    subtractWarhouse: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfWarhouse",
    },

    subtractShop: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfShop",
    },
    addquantitydialog: function (newValue) {
      this.totalSubtractCountOfWarhouse = "0";
      this.totalSubtractCountOfShop = "0";
      this.subtractWarhouse.length = 0;
      this.subtractShop.length = 0;
    },
  },
  methods: {
    initialize() {
      axios
        .get(`Cart/show_specific_purchase_invoice/${this.$route.query.id}/`)
        .then((response) => {
          this.invoiceinfo = response.data.data;
          this.orders = response.data.data.all_orders;
          this.myinfo = response.data.data.company_info;
          this.billing_info = response.data.data.billing_info;
          this.invoieDate = new Date(
            response.data.data.invoice_info.date
          ).toDateString();
          // console.log("all data", response.data.data);
        });
    },
    ApprovechildOrder() {
      axios
        .get(`productdetails/approve_purchase_order/${this.$route.query.id}/`)
        .then((response) => {
          if (response.data.success) {
            //this.text = "Order approved";
            this.text = response.data.message;
            this.snackbar = true;
            this.showConfirmButton = false;
            this.showCancelButton = false;
            this.orderStatus = "Confirmed";
          } else {
            this.text = response.data.message;
            // this.text = "Something went wrong";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong";
          this.snackbar = true;
        });
    },

    printData() {
      //this.$htmlToPaper("dataapp");
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.tableheader {
  max-height: 32px !important;
}
/* tbody tr:nth-of-type(even) {
  background-color: #e3f2fd !important;
} */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr :nth-child(even){
 
  background-color: #ff7743;
 
}
table.v-table thead tr{
 max-height:1px !important;
} */
/* 
table.v-table thead tr:nth-child(even) {
  background-color: #dddddd;
} */
/* .invoice{
  background: #f5f5f5; 
  font-size: 10em
}
.invoice .theme--light.v-table{
  background-color: #00f !important;
} */
</style>